<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-dark sticky-top bg-dark">
      <div class="container-fluid">
        <router-link class="navbar-brand" to="/" replace>
          <img id="logo" :src="require('../assets/logo.png')" alt="vrtag" />
        </router-link>
        <button v-if="!isLogin" class="navbar-toggler" @click="toggleMenu()" type="button" aria-controls="navbarCollapse"
          aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="!isLogin" class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav ms-auto mb-2 mb-md-0">
            <li class="nav-item"><router-link class="nav-link active" to="/sync" replace>Synchronize</router-link></li>
            <li class="nav-item"><router-link class="nav-link active" to="/support" replace>Support</router-link></li>
            <li class="nav-item"><router-link class="nav-link active" to="/about" replace>About VRTag</router-link></li>
            <li class="nav-item"><a class="nav-link active" @click="doLogout()">Log off</a></li>
          </ul>
        </div>
      </div>
    </nav>

    <div>
      <div id="container_vue" class="container">
        <router-view />
      </div>
      <div id="container_module" class="m-0 mb-2 p-0" hidden>
        <button id="btnModuleClose" type="button" class="btn-close float-end p-2" aria-label="Close" onclick="application.module.unloadModule()"></button>
        <button id="btnModuleToggleMarkers" type="button" class="btn-close float-end p-2" aria-label="Hide" onclick="application.module.markersToggle()"><i class="fas fa-eye"></i></button>
        <button id="btnModuleReset" type="button" class="btn-close float-end p-2" aria-label="Reset" onclick="application.module.markersReset()"><i class="fas fa-trash"></i></button>
        <div id="container_image">
          <div id="container_markers"></div>
          <img src="" id="new_marker" alt="">
          <img src="" id="app_module" alt="" @contextmenu="onModuleContextMenu">
        </div>
      </div>
      <div id="container_module_info" class="px-3 mb-3 small text-muted" hidden></div>
    </div>

    <div class="modal" id="infoModal" tabindex="-1" aria-labelledby="infoModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header py-1">
            <h5 class="modal-title" id="infoModalLabel">...</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body py-2 overflow-auto" style="max-height:350px">
            <p class="description m-0 p-0" id="infoModalDescription">...</p>
          </div>
          <div class="modal-footer pt-1 pb-3">
            <button type="button" id="infoModalOK" class="btn btn-primary px-3 py-2 me-auto">OK</button>
            <button type="button" id="infoModalCancel" class="btn btn-secondary px-3 py-2">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" id="postModal" tabindex="-1" aria-labelledby="postModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header py-1">
            <h5 class="modal-title" id="postModalLabel">...</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onclick="application.module.resetPost()"></button>
          </div>
          <div class="modal-body py-2 overflow-auto" style="max-height:350px">
            <div class="description m-0 p-0" id="postModalDescription">
              <div class="html">
                <!-- HTML will be loaded here on the fly -->
              </div>
            </div>
            <div class="buttons">
              <button class="btn btn-primary px-3 py-2 m-3 ms-0 me-auto" onclick="application.module.createTimeslot()">Timeslot</button>
              <!--button class="btn btn-primary px-3 py-2 m-3 me-auto" onclick="application.module.createFinding()">Finding</button-->
            </div>
          </div>
          <div class="modal-footer pt-1 pb-3">
            <button type="button" id="postModalOK" class="btn btn-primary px-3 py-2 me-auto" data-bs-dismiss="modal">OK</button>
            <button type="button" id="postModalCancel" class="btn btn-secondary px-3 py-2" data-bs-dismiss="modal" onclick="application.module.resetPost()">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed-bottom bg-dark text-center text-light small py-1" v-if="isMobile()">
      {{ name }} - <span class="small">{{ version }}</span><span v-if="!app.api.isOnline" class="small ps-2 blink"><i
          class="fas fa-wifi" style="color:red"></i></span>
    </footer>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      server: this.app.server(),
      name: this.app.name,
      version: this.app.version,
      user: this.app.user,
      qrModalVisible: false
    }
  },
  computed: {
    isLogin() {
      const route = useRoute();
      return (route.path == '/login');
    },
    qrSource() {
      return 'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' + this.server;
    },
  },
  methods: {
    toggleMenu() {
      try {
        let el = document.getElementById('navbarCollapse');
        if (el) {
          el.classList.toggle('show');
        }
      } catch (error) {
        console.error(error);
      }
    },
    doLogout() {
      this.app.logout();
      this.$router.replace('/login');
    },
    doRestartApp() {
      this.app.logout();
      location.reload(true);
    },
    isMobile() {
      return this.app.isMobile();
    },
    onLoaded(e) {
      console.log("on loaded", e)
    },
    onDecode(result) {
      document.getElementById('qrcode').value = result;
      // for now I am calling same cancel function to hide the modal, if needed new function can be called
      this.app.qr.cancel();
      console.log("Scanned Result", result)
    }
  },
  mounted() {
    console.log('app.mounted');

    // Add the bootstrap reference to the element in the DOM
    let postModal = document.getElementById('postModal');
    postModal._modal = new Modal(postModal, {
      backdrop: true,
      keyboard: true,
      focus: true
    });

    // Add the bootstrap reference to the element in the DOM
    let infoModal = document.getElementById('infoModal');
    infoModal._modal = new Modal(infoModal, {
      backdrop: true,
      keyboard: true,
      focus: true
    });

    // When the info modal is closed, certain properties are reset (position, opacity of the backdrop, visibility of buttons, onclick of buttons)
    infoModal.addEventListener('hidden.bs.modal', () => {
      let modal = document.getElementById('infoModal');
      modal.style.top = '0px';
      //let backdrop = document.querySelector('.modal-backdrop.show');
      //backdrop.style.opacity = '0.05';
      let btns = document.querySelectorAll('#infoModal button');
      for (let btn of btns) {
        btn.hidden = false;
        // Remove any onclick function - just removing the attribute didn't seem to work, so we set it to null first
        btn.onclick = null;
        btn.removeAttribute('onclick');
      }
    });
  }
};
</script>

<style>
/* https://stackoverflow.com/questions/36212722/how-to-prevent-pull-down-to-refresh-of-mobile-chrome */
html,
body {
  overscroll-behavior-y: contain;
}

#logo {
  height: 25px;
}

/* Buttons with icons should have some spacing (unless they consist ONLY of an icon) */
button.no-icon-space i.fa,
a.no-icon-space i.fa,
button.no-icon-space svg,
a.no-icon-space svg {
  margin-right: 0;
}
button i.fa,
a i.fa,
button svg,
a svg {
  margin-right: 0.5em;
}
.tiny {
  font-size: 80%;
}
.bg-dark {
  background-color: #276183 !important;
}
.form-label {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.nodecoration {
  text-decoration: none;
  color: inherit;
}
.missing {
  color: red;
}
.text-black-25 {
  /* Mostly used for the uploaded cloud icon */
  opacity: 0.25;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.btn-camswitch,
.btn-camflash {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  filter: drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white) drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white);
}
.btn-camflash[data-flash=true] {
  opacity: 1;
  color: white;
  filter: drop-shadow(2px 0 0 gray) drop-shadow(0 2px 0 gray) drop-shadow(-2px 0 0 gray) drop-shadow(0 -2px 0 gray)
}
.btn-camclose {
  filter: drop-shadow(2px 0 0 white) drop-shadow(0 2px 0 white) drop-shadow(-2px 0 0 white) drop-shadow(0 -2px 0 white);
}
.btn-camshot {
  position: absolute;
  bottom: 1.7rem;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid #000;
  opacity: 0.75;
  background-color: #fff;
  filter: drop-shadow(0px 0px 2px white);
}

label {
  white-space: pre-wrap;
}

/* tomSelect for selects */
.ts-wrapper .option p {
  display: block;
  padding: 0;
  margin: 0;
}

.ts-wrapper .option p:nth-child(n+2) {
  font-size: 12px;
  display: block;
  color: #a0a0a0;
}

fieldset:disabled * {
  /* This disables mouse interactions with tom-select elements */
  pointer-events: none;
}

fieldset:disabled .ts-wrapper {
  background-color: #e9ecef;
}

fieldset:disabled svg {
  display: none;
}

fieldset:disabled .btn,
fieldset:disabled .btn-check {
  opacity: 0.3 !important;
  filter: saturate(0);
}

/* Position the clear button (x) to the left of the dropdown caret */
.plugin-clear_button.focus.has-items .clear-button,
.plugin-clear_button:hover.has-items .clear-button {
  margin-right: 2em !important;
}

/* When presenting the specified values, honor the white spacing and cr/lfs */
#infoModalDescription {
  white-space: pre-wrap;
}

#infoModalDescription td {
  width: 50%;
}

#container_module {
  width: 100vw;
  height: 75vh;
  display: inline-block;
  overflow: hidden;
  border-bottom: 3px solid #eee;
  background-color: #eee;
  /* This gives extra space to fully show a marker, in case it's at 100% */
  padding-bottom: 32px !important;
}

/* Style the buttons */
#container_module button.btn-close svg {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  top: 6px;
  left: 6px;
}

#btnModuleClose,
#btnModuleToggleMarkers,
#btnModuleReset {
  background-color: #f4f7fae0;
  border: 2.5px solid #a0a0a0;
  position: fixed;
  overflow: hidden;
  z-index: 1;
  right: 0.5rem;
  opacity: 1;
}
#btnModuleClose {
  top: 4rem;
}
#btnModuleToggleMarkers {
  background-image: none;
  top: 6.5rem;
}
#btnModuleReset {
  background-image: none;
  top: 9rem;
}
#btnModuleReset svg {
  top: 7px;
  left: 7px;
}
#btnModuleClose:focus,
#btnModuleToggleMarker:focus,
#btnModuleReset:focus {
  box-shadow: none;
}

#container_image {
  transform-origin: top left;
  position: relative;
  cursor: crosshair;
}
#container_markers {
  position: relative;
}

/* This value is used for scaling dots on modules and will be changed when zooming in or out by module.markersResize() */
:root {
  --module_zoom: 1;
}
.modal {
  opacity: 0.94;
}

#new_marker,
.marker {
  position: absolute;
  transform-origin: center;
  border-radius: 25px;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
}
.marker.location {
  /* black to orang #ff6c00 */
  filter: invert(57%) sepia(89%) saturate(3926%) hue-rotate(359deg) brightness(100%) contrast(109%);
}
.marker.done {
  /* black to green #004f0c */
  filter: invert(18%) sepia(22%) saturate(5428%) hue-rotate(112deg) brightness(100%) contrast(102%);
  opacity: 0.6;
}
.marker.hidden {
  opacity: 0.15;
}
#btnModuleToggleMarkers.hidden svg {
  opacity: 0.4;
}

#new_marker {
  /* black to blue #006b9c */
  filter: invert(23%) sepia(38%) saturate(4520%) hue-rotate(179deg) brightness(99%) contrast(101%);
  visibility: hidden;
}
#new_marker:hover,
#container_markers .marker:hover {
  cursor: pointer;
}

#app_module {
  width: 100vw;
  z-index: 0;
  display: block;
}

/* Blinking */
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.blink {
  animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}
</style>