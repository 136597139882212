export default class Module {
    constructor() {}

    initialize(storage, moduleName, moduleInfo, container_module_id = 'container_module', container_image_id = 'container_image', showMarkers = false ) {
        // Get handles to relevant elements; wrapper is the parent container and viewport, holder contains the marker-container and the image element
        console.log('module.initialize(<storage>,' + moduleName + ',' + container_module_id + ',' + container_image_id + ')');
        this.storage = storage;

        // Remember the module name and reference point, so forms can pickup this when they need to
        this.moduleName = moduleName;
        this.moduleReference = '';
        this.moduleInfo = moduleInfo;

        // The scale is initially set at 1; the change speed when zooming in/out is set at 5%
        this.scale = 1;
        this.scale_min = 1;
        this.scale_change = 0.05;

        // These are used to keep and calculate the point from where to resize/pinch/zoom
        this.target = { x: 0, y: 0 };
        this.translation = { x: 0, y: 0 };

        // Used to determine mouse and touh interactions (click and drag panning for mouse, or touh and drag for screen)
        this.mouseDown = false;
        this.touchDown = false;
        this.touchDistance = 0;
        this.pointerCoord = { x: 0, y: 0 };

        // This will remember if the user was panning the module - in which case after the mouse-up we don't want a click
        this.moduleWasDragged = false;

        // These hold the module-related elements: the container and the image container
        this.wrapper = document.getElementById(container_module_id);
        this.holder = document.getElementById(container_image_id);
        this.holder.style.transform = `translate(0px, 0px) scale(${this.scale}, ${this.scale})`;
        this.module = document.getElementById('app_module');

        try {
            // When the user clicks the marker for a new point
            document.getElementById('new_marker').onclick = () => { this.markerNewClick() };

            // Install listeners for zooming the loaded module
            this.holder.onclick = () => { this.onModuleClick() };
            this.holder.onwheel = () => { this.onModuleWheel() };
            this.holder.onmousedown = () => { this.onModuleMouseDown() };
            this.holder.onmousemove = () => { this.onModuleMouseMove() };
            this.holder.onmouseup = () => { this.onModuleMouseUpOrLeave() };
            this.holder.onmouseleave = () => { this.onModuleMouseUpOrLeave() };
            this.holder.ontouchstart = () => { this.onModuleTouchStart() };
            this.holder.ontouchend = () => { this.onModuleTouchEnd() };
            this.holder.ontouchmove = () => { this.onModuleTouchMove() };

            // This doesn't work on Safari yet
            if( screen.orientation ) {
                screen.orientation.onchange = () => { this.onModuleOrientationChange() };
            } else {
                // Unknown if any of these alternatives work, so ignore Safari for now and leave it as a todo
                // window.addEventListener('orientationchange', this.onModuleOrientationChange );
                // document.getElementsByTagName('body')[0].addEventListener('orientationchange', function() { this.onModuleOrientationChange(); });
                // window.orientation.onchange = () => { this.onModuleOrientationChange() };
            }
        } catch( e ) {
            console.log( e.message );
        }

        if( showMarkers ) {
            this.markersClear();
            this.markersDisplay();
        }
    }

    loadModule(module, info, show, id = 'app_module') {
        // Variable <info> contains relevant information to send to the form
        // Variable <show> contains info to display so the user knows what module is shown
        console.log('module.loadModule(' + module + ',<info>,<show>,' + id + ')');
        let self = this;

        // The user will come back to this module after closing the form that was opened from here
        window.application.formOpener = 'module';

        // Load the blob from the indexedDB
        window.application.storage.loadBlob('modules.' + module, id)
            .then(() => {
                let moduleInfo = [];
                for (let key in show) {
                    moduleInfo.push(window.application.escapeHTML(key) + ': ' + window.application.escapeHTML(show[key]));
                }
                document.getElementById('container_module_info').innerHTML = moduleInfo.join('<br>');

                document.getElementById('container_vue').hidden = true;
                document.getElementById('container_module').hidden = false;
                document.getElementById('container_module_info').hidden = false;

                // Initialize the module functions 
                // Using even 2 requestAnimationFrame did not work, so we'll give it 1/10th of a second to paint, followed by one requestAnimationFrame :-(
                window.setTimeout(() => {
                    requestAnimationFrame(() => {
                        self.initialize(window.application.storage, module, info, 'container_module', 'container_image', true );
                    });
                }, 100);
            });
    }

    unloadModule(id = 'container_module') {
        console.log('module.unloadModule(' + id + ')');
        let self = this;

        // Hide the element, set the image to a transparent pixel
        let el = document.getElementById(id);
        el.hidden = true;
        el.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+P+/HgAFhAJ/wlseKgAAAABJRU5ErkJggg==";

        // The user no longer will come back to this module after closing the form that was opened from here, but instead will go back to the list
        window.application.formOpener = 'list';

        // Forms no longer should pickup the module reference
        self.moduleName = '';
        self.moduleReference = '';
        self.moduleInfo = {};

        document.getElementById('container_module_info').hidden = true;
        document.getElementById('container_module').hidden = true;
        document.getElementById('container_vue').hidden = false;
    }

    onModuleContextMenu() {
        // This is to disable the accidental right-click on images
        let event = window.event;

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onModuleClick() {
        console.log('module.onModuleClick()');
        let self = this;

        let event = window.event;
        if (!self.moduleWasDragged) {
            // Calculate the percentages (50,50 is obviously the middle of the module)
            // Set this to a global so the code that initializes the forms can pickup this value
            let x = parseFloat(100 * event.offsetX / self.holder.offsetWidth).toFixed(4);
            let y = parseFloat(100 * event.offsetY / self.holder.offsetHeight).toFixed(4);
            self.moduleReference = x + ',' + y + '@' + self.moduleName.replace('/', '_');

            let size = 1 / self.scale;
            self.openDialog({
                id: 'new_marker',
                src: require('/assets/marker.png'),
                style: {
                    left: event.offsetX +'px',
                    top: event.offsetY +'px',
                    visibility: 'visible',
                    transform: `scale(${size}, ${size})`,
                },
                dataset: {
                    fk_module: self.moduleInfo.id,
                    id: null,
                    x: x,
                    y: y
                }
            });
        }
    }

    onModuleWheel() {
        console.log('module.onModuleWheel()');
        let self = this;
        let event = window.event;

        if (event) {
            try {
                event.preventDefault();

                self.holder = document.getElementById('container_image');
                self.wrapper = document.getElementById('container_module');

                let mouse = {
                    x: event.clientX - (self.wrapper.offsetLeft + self.wrapper.clientLeft - window.scrollX),
                    y: event.clientY - (self.wrapper.offsetTop + self.wrapper.clientTop - window.scrollY)
                };

                self.target.x = (mouse.x - (self.translation.x)) / self.scale;
                self.target.y = (mouse.y - (self.translation.y)) / self.scale;

                if (event.deltaY < 0) {
                    if (window.innerWidth / (self.module.naturalWidth / self.scale) < 3) {
                        self.scale *= (1 + self.scale_change);
                    }
                } else {
                    if (self.scale >= (self.scale_min + self.scale_change)) {
                        self.scale *= (self.scale_min - self.scale_change);
                    } else {
                        self.scale = self.scale_min;
                    }
                }
                self.translation.y = -self.target.y * self.scale + mouse.y;
                self.translation.x = -self.target.x * self.scale + mouse.x;

                // Snap back into place
                if (self.scale == self.scale_min) {
                    self.translation.x = 0;
                    self.translation.y = 0;
                }
                self.holder.style.transform = `translate(${self.translation.x}px, ${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
                self.markersResize();
            } catch (error) {
                console.error(error);
            }
        }
    }

    onModuleMouseDown() {
        console.log('module.onModuleMouseDown()');
        let self = this;
        let event = window.event;

        if (event && event.button == 0) {
            self.mouseDown = true;
            self.moduleWasDragged = false;
            self.pointerCoord.x = event.clientX - (self.translation.x);
            self.pointerCoord.y = event.clientY - (self.translation.y);
        }
    }

    onModuleMouseMove() {
        let event = window.event;
        if (event) {
            event.preventDefault();
            let self = this;

            // Remember when mouse-release: the user panned the module, so no click please
            self.moduleWasDragged = true;

            if (self.mouseDown) {
                console.log('module.onModuleMouseMove()');
                self.translation.x = event.clientX - self.pointerCoord.x;
                self.translation.y = event.clientY - self.pointerCoord.y;
                self.holder.style.transform = `translate(${self.translation.x}px, ${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
            }
        }
    }

    onModuleMouseUpOrLeave() {
        console.log('module.onModuleMouseUpOrLeave()');
        let self = this;
        self.mouseDown = false;

        // Snap back into place
        if (self.translation.x > 0) {
            self.translation.x = 0;
        }
        if (self.translation.y > 0) {
            self.translation.y = 0;
        }
        self.holder.style.transform = `translate(${self.translation.x}px, ${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
    }

    onModuleTouchStart() {
        console.log('module.onModuleTouchStart()');
        let self = this;
        let event = window.event;

        if (event) {
            self.touchDown = true;
            self.pointerCoord.x = event.touches[0].clientX - (self.translation.x);
            self.pointerCoord.y = event.touches[0].clientY - (self.translation.y);
        }
    }

    onModuleTouchEnd() {
        console.log('module.onModuleTouchEnd()');
        let self = this;

        self.touchDown = false;
        self.touchDistance = 0;

        this.onModuleMouseUpOrLeave();
    }

    onModuleTouchMove() {
        console.log('module.onModuleTouchMove()');
        let self = this;
        let event = window.event;

        if (event) {
            switch (event.touches.length) {
                case 1:
                    if (self.touchDown) {
                        let touch = {
                            x: event.touches[0].clientX,
                            y: event.touches[0].clientY
                        }
                        self.translation.x = touch.x - self.pointerCoord.x;
                        self.translation.y = touch.y - self.pointerCoord.y;

                        // Limit scale to minimum, snap back in place
                        if (self.scale <= self.scale_min) {
                            self.scale = self.scale_min;
                            self.translation.x = 0;
                            self.translation.y = 0;
                        }
                        self.holder.style.transform = `translate(${self.translation.x}px, ${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
                    }
                    break;

                case 2:
                    // Two fingers, meaning zooming/pinching
                    var distance = Math.hypot(
                        event.touches[0].clientX - event.touches[1].clientX,
                        event.touches[0].clientY - event.touches[1].clientY
                    );

                    if (self.touchDistance != 0) {
                        let center = {
                            x: (event.touches[0].clientX + event.touches[1].clientX) / 2,
                            y: (event.touches[0].clientY + event.touches[1].clientY) / 2
                        };
                        self.target.x = (center.x - (self.translation.x)) / self.scale;
                        self.target.y = (center.y - (self.translation.y)) / self.scale;
                        self.scale = self.scale * (distance / self.touchDistance);
                        self.translation.x = -self.target.x * self.scale + center.x;
                        self.translation.y = -self.target.y * self.scale + center.y;

                        // Limit scale to its minimum, snap back in place
                        if (self.scale <= self.scale_min) {
                            self.scale = self.scale_min;
                            self.translation.x = 0;
                            self.translation.y = 0;
                        }
                        self.holder.style.transform = `translate(${self.translation.x}px,${self.translation.y}px) scale(${self.scale}, ${self.scale})`;
                        self.markersResize();
                    }
                    self.touchDistance = distance;
                    break;

                default:
                    // We dont deal with 3 or more fingers (or zero)
                    break;
            }
        }
    }

    onModuleOrientationChange() {
        console.log('module.onModuleOrientationChange()');
        let self = this;
        self.reset();
    }

    reset() {
        console.log('module.reset()');
        let self = this;

        self.markersClear();

        document.getElementById('infoModal')._modal.hide();

        setTimeout(() => {
            requestAnimationFrame(() => {
                self.target = { x: 0, y: 0 };
                self.translation = { x: 0, y: 0 };
                self.pointerCoord = { x: 0, y: 0 };
                self.touchDistance = 0;
                self.scale = self.scale_min;
                self.holder.style.transform = `translate(0px,0px) scale(${self.scale}, ${self.scale})`;

                self.markersClear();
                self.markersDisplay();
            });
        }, 50);
    }

    moduleClick() {
        console.log('module.moduleClick()');
    }

    markersLoad(fk_module) {
        console.log('module.markersLoad(' + fk_module + ')');
        let self = this;

        // Get all created locations, turn them into an array so we can .find() stuff
        let locations = self.storage.getAll('created.plan.');
        locations = Object.values(locations);

        // Get the planned locations
        let result = [];
        let locations_planned = self.storage.get('info.plan');
        for( let idx in locations_planned ) {
            if( locations_planned[idx].fk_module == fk_module ) {
                // See if the location is done already
                var lookup = locations.find( location => location.fk_plan === locations_planned[idx].id );
                result.push({
                    id: locations_planned[idx].id,
                    x: locations_planned[idx].x,
                    y: locations_planned[idx].y,
                    type: 'location',
                    done: (lookup ? true : false),
                });
            }
        }

        // Turn the planned locations into an Array so we can .find() stuff
        locations_planned = Object.values(locations_planned);

        // Add the unplanned locations
        // These are locations that are in the database, but for which we didn't or no longer (hq might update) have a plan
        for( let idx in locations ) {
            if( locations[idx].fk_module == fk_module ) {
                lookup = locations_planned.find( location_planned => location_planned.id === locations[idx].fk_plan );
                if( lookup ) {
                    // This is a planned location, ignore it as it is already in the result list
                } else {
                    // This is an adhoc location
                    result.push({
                        id: locations[idx].id,
                        x: locations[idx].x,
                        y: locations[idx].y,
                        type: 'adhoc',
                        done: true,
                    });
                }
            }
        }

        return result;
    }

    markersReset() {
        console.log('module.markersReset()');
        let self = this;

        // Ask confirmation if the user wants to reset all markers for this drawing
        // Future: Popup a selection with check boxes (default checked) for all marker types to reset
        window.application.confirm(
            'Do you want to remove all previous location markers?\nThese will be reloaded when you synchronize with the server.',
            'VR Tag',
            () => {
                var plans = window.application.storage.get('info.plan');
                var plans_new = [];
                for( var plan_id in plans ) {
                    if( plans[plan_id].fk_module !== self.moduleInfo.id ) {
                        plans_new.push(plans[plan_id]);
                    }
                }
                window.application.storage.set( 'info.plan', plans_new );
                window.application.module.reset();
            }
        );
    }

    markersToggle( type = 'location' ) {
        console.log('module.markersToggle(' +type +')');

        //let markers = document.getElementsByClassName('marker');
        let markers = document.querySelectorAll( '.marker.' +type );
        for (var cnt = 0; cnt < markers.length; cnt++) {
            markers[cnt].classList.toggle('hidden');
        }
        document.getElementById('btnModuleToggleMarkers').classList.toggle('hidden');
    }

    markersResize() {
        let markers = document.querySelectorAll('#new_marker, .marker');
        let scale = this.scale;
        let size = 1 / scale;
        for (let marker of markers) {
            marker.style.transform = `scale(${size}, ${size})`;
        }
        var r = document.querySelector(':root');
        r.style.setProperty( '--module_zoom', this.scale );
    }

    markersClear() {
        console.log('module.markersClear()');
        let containerEl = document.getElementById('container_markers');
        containerEl.innerHTML = '';
    }

    markersDisplay() {
        console.log('module.markersDisplay()');
        let self = this;

        // Load the markers from the database
        if (self.moduleName) {
            let markers = self.markersLoad(self.moduleInfo.id);
            let containerEl = document.getElementById('container_markers');
            let markerHTML = '';

            for (let marker of markers) {
                markerHTML += '<img ' +
                    'src="' + require("/assets/marker.png") + '"' +
                    'class="marker ' +marker.type +(marker.done ? ' done' : '') +'" ' +
                    'style="' + self.markerCalcPosition(marker.x, marker.y) + '"' +
                    'data-x="' +marker.x +'"' +
                    'data-y="' +marker.y +'"' +
                    'data-id="' + marker.id + '"' +
                    'data-type="' + marker.type + '"' +
                    'data-fk_module="' +self.moduleInfo.id +'"' +
                    'onclick="application.module.onMarkerClick(this)"' +
                    '/>';
            }
            containerEl.innerHTML = markerHTML;
        }
    }

    markerCalcPosition(x, y) {
        // Dimension of the dot is 18x18; adjust accordingly
        let left = 0;
        let top = 0;
        if (this.holder) {
            left = ((this.holder.offsetWidth / 100) * x);
            top = ((this.holder.offsetHeight / 100) * y);
        } else {
            console.error('markerCalcPosition: this.holder not available.');
        }
        return `left:${left}px; top:${top}px; transform:scale(${1/this.scale}, ${1/this.scale})`;
    }

    onMarkerClick(marker) {
        console.log('module.onMarkerClick()');
        let self = this;

        // Stop the click here (so we won't get a click on the module)
        let event = window.event;
        event.preventDefault();
        event.stopPropagation();

        // An existing marker is given; proceed to create a new one based on its properties
        let size = 1 / self.scale;
        self.openDialog({
            id: 'new_marker',
            src: require('/assets/marker.png'),
            style: {
                left: marker.style.left,
                top: marker.style.top,
                visibility: 'visible',
                transform: `scale(${size}, ${size})`,
            },
            dataset: {
                fk_module: self.moduleInfo.id,
                id: null,
                x: marker.dataset.x,
                y: marker.dataset.y,
            }
        });
    }

    closeDialog() {
        console.log( 'module.closeDialog()' );
        let self = this;

        document.getElementById('postModal')._modal.hide();
        self.resetPost();
    }

    openDialog(marker) {
        console.log( 'module.openDialog(<marker>)' );
        let self = this;
        self.marker = marker;

        try {
            // Put down a new marker
            let el = document.getElementById(marker.id);
            el.src = marker.src;
            el.style = marker.style;
            for( var s in marker.style ) {
                el.style[s] = marker.style[s];
            }
            for( var d in marker.dataset ) {
                el.dataset[d] = marker.dataset[d];
            }

            document.getElementById('postModalLabel').innerText = 'Create new..';
            document.querySelector('#postModal .modal-footer').hidden = true;

            let height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
            let modal = document.querySelector('#postModal');
            modal.style.top = (height - 210) + 'px';
            modal._modal.show();

            // Fix the backdrop
            let backdrop = document.querySelector('.modal-backdrop.show');
            backdrop.style.opacity = '0.05';
        } catch( error ) {
            alert( error.message );
        }
    }

    resetPost() {
        console.log( 'module.resetPost()' );

        // This reset the modal to it's initial setting
        document.querySelector( '#postModalLabel' ).innerText = 'Create new..';
        document.querySelector( '#postModal .html' ).innerHTML = '';

        document.querySelector( '#postModal .modal-body .buttons' ).hidden = false;
        document.querySelector( '#postModal .modal-footer' ).hidden = true;
        document.querySelector( '#postModalOK' ).hidden = false;

        // Also cancel (=hide) the marker
        document.getElementById('new_marker').style.visibility = 'hidden';
    }

    createPost( componentName, title = 'New post', onSave = null ) {
        console.log( 'module.createPost(' +componentName +',' +title +',<onSave>)' );

        return new Promise(function (resolve, reject) {
            window.application.loadHTML( componentName, '#postModal .html' )
            .then( () => {
                document.querySelector( '#postModalLabel' ).innerText = title;

                document.querySelector( '#postModal .modal-body .buttons' ).hidden = true;
                document.querySelector( '#postModal .modal-footer' ).hidden = false;
                if( onSave ) {
                    document.querySelector( '#postModalOK' ).onclick = onSave;
                }

                var modal = document.getElementById('postModal')._modal;
                resolve(modal);
            })
            .catch( (e) => {
                // Something went wrong, show it to the user
                document.querySelector( '#postModalLabel' ).innerText = 'Error';
                document.querySelector( '#postModal .html' ).innerText = e;

                document.querySelector( '#postModal .modal-body .buttons' ).hidden = true;
                document.querySelector( '#postModal .modal-footer' ).hidden = false;
                document.querySelector( '#postModalOK' ).hidden = true;
                reject(e);
            });
        });
    }

    createTimeslot() {
        console.log( 'module.createTimeslot()' );
        this.createPost( 'timeslot_create', 'Create a Timeslot', () => {
            window.application.module.timeslot.save().then( () => {
                // Close the dialog, redraw the markers
                window.application.module.closeDialog();
                window.application.module.markersClear();
                window.application.module.markersDisplay();
            });
        })
        .then( (modal) => {
            let self = this;
            window.application.module.timeslot.start( modal, self.marker );
        })
        .catch( (e) => {
            console.error( e );
        });
    }

    createFinding() {
        console.log( 'module.createFinding()' );
        this.createPost( 'finding_create', 'Create a Finding', () => {
            alert( 'saved!');
            window.application.module.resetPost();
        });
    }
}